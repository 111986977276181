<template>
	<div>
		<div :id="'paypal-button-container-' + paypalButtonType" style="width: 265px; margin: 0 auto;"></div>
		<p id="result-message"></p>
	</div>
</template>

<script>
import { emitter } from '../../main';
const axios = require('axios')
export default {
	components: {},
	mixins: [],
	props: {
		paypalButtonType: {
			type: String,
			default: ''
		},
		orderInFlight: {
			type: Boolean,
			default: false,
		},
		billingFormRef: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			paypal: {}
		}
	},
	created() {},
	mounted() {
		this.mountPayPalButtons()
	},
	methods: {
		async mountPayPalButtons() {
			await this.$loadPaypal()
			.then((paypal) => {
				try {
					paypal.Buttons({
						fundingSource: this.paypalButtonType,
						style: {
							layout: 'vertical',
							color:  'gold',
							shape:  'sharp',
							label:  'checkout',
							height: 46
						},
						onClick: (data, actions) => {
							return new Promise((resolve) => {
								let formIsValid = this.billingFormRef.preValidateBillingAddress()
								if(formIsValid) {
									return resolve(actions.resolve())
								} else {
									return resolve(actions.reject())
								}
							})
						},
						async createOrder() {
							return axios.get('/api/paypal/create-order')
								.then(response => {
									if(response.data.success) {
										return response.data.response.id
									} else {
										emitter.emit("paypal-failed-create-order")
									}
								}).catch((error) => {
									emitter.emit("paypal-failed_create-order")
								})
								.finally(() => {
									// this.hideLoadingOverlay()
								})
						},
						onApprove: async () => {
							axios.get('/api/paypal/get-paypal-guid')
								.then(response => {
									if(response.data.status = "success") {
										emitter.emit("paypal-complete", {paypalGuid: response.data.guid})
										return response.data.guid
									} else {
										emitter.emit("paypal-failed-get-paypal-guid")
									}
								})
						},
					}).render("#paypal-button-container-" + this.paypalButtonType);
				} catch {
					console.error("failed to render the PayPal Buttons")
				}
			});
		}
	}
};
</script>