<template>
	<div>
		<CheckoutSteps page="billing" :needs-additional-shipments-step="getPerpetualSubscription" />
		<div class="master-shipping-panel">
			<div class="row">
				<div class="col-md-12 p-lr-5">
					<h3 class="m-t-25 m-b-25 text-center-mobile billing-heading">Billing Details</h3>
				</div>
			</div>

			<div class="cart-div-mobile-fix payment-page">
				<div class="row">
					<div class="col-md-8">						
						<div class="form-bg">
							<div class="form-bg" style="padding: 10px">
								<h4 class="m-b-0 inline-block">Payment Method</h4>
								<ul class="nav nav-tabs checkout-payment-tabs-list" role="tablist">
									<li :class="{'active': ccTabActive}">
										<a href="javascript:;" @click="paymentMethodTabChange('cc-tab')">
											<label for="payment-method-credit-card" class="" tabindex="1">
												<span class="payment-options-text">Credit Card</span>
												<i class="fa fa-cc-discover icon-lg" aria-hidden="true" />
												<i class="fa fa-cc-mastercard icon-lg" aria-hidden="true" />
												<i class="fa fa-cc-visa icon-lg" aria-hidden="true" />
												<i class="fa fa-cc-amex icon-lg" aria-hidden="true" />
											</label>
										</a>
									</li>
									<li v-if="!hasAutoDelivery" :class="{'active': payPalTabActive}">
										<a href="javascript:;" @click="paymentMethodTabChange('paypal-tab')">
											<label class="" style="font-size: 18px;">
												PayPal <i class="fa fa-paypal" aria-hidden="true" style="font-size: 16px; padding-left:10px" />
											</label>
										</a>
									</li>
									<li v-if="isPayPalTestB && !hasAutoDelivery" :class="{'active': payPalInstallmentTabActive}">
										<a href="javascript:;" @click="paymentMethodTabChange('paypal-installment-tab')">
											<label class="" style="font-size: 18px;">
												Pay Later <i class="fa fa-paypal" aria-hidden="true" style="font-size: 16px; padding-left:10px" />
											</label>
										</a>
									</li>
								</ul>
		
								<!-- Tab panes -->
								<div class="tab-content">
									<div v-if="ccTabActive" id="cc-tab" class="tab-pane fade" :class="{'active in': ccTabActive}">
										<div class="p-t-25 tab-pane-bordered-box sg-form" style="display: flex; flex-direction: column;">
											<div v-if="showSavedCardSelection">
												<div class="flexbox">
													<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 p-t-20" :class="{'has-error': validationErrors.selected_payment}">
														<div class="col-md-4 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
															<label class="align-self-c" for="select-card"><span class="required-asterisk color-primary">*</span>SELECT CARD</label>
														</div>
														<div class="col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
															<div class="arrow">
																<select
																	id="select-card"
																	v-model="selectedPaymentID"
																	class="form-control"
																	autocomplete="false"
																	tabindex="1"
																	@change="chooseSavedPaymentSource()"
																>
																	<option value="--">Select a Card</option>
																	<option v-for="(data, key) in savedPaymentMethods" :key="key" :value="key">{{ data.card_info.acct_type + ' (*' + data.card_info.acct_last4 + ') - ' + data.billing_address.fname + ' ' + data.billing_address.lname +' exp. '+ data.card_info.expr }}</option>
																	<option value="new">Use a New Card</option>
																</select>
															</div>
														</div>
														<div v-if="validationErrors.selected_payment" class="col-lg-7 col-md-7 col-lg-offset-5  col-md-offset-5 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 ">
															<span class="color-primary inline-block">{{ validationErrors.selected_payment }}</span>
														</div>
													</div>
												</div>
											</div>

											<BillingAddressForm
												ref="billingAddressFormRefCC"
												:show-address-form="showAddressForm"
												:opted-in-phone-list="optedInPhoneNumbers"
												:saved-card-is-selected-prop="savedCardIsSelected"
												:saved-card-billing-address="savedCardBillingAddress"
												@qas-fingerprint="onQasFingerprintChange"
											/>

											<div>
												<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<div class="col-md-8 col-md-offset-4 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
														<GiftCertificateInput />
													</div>
												</div>
											</div>

											<div v-if="showNewCardFrame " class="m-t-20">
												<div class="new-card-info-header">Card Details
													<span v-if="chaseHPFPaymentComplete">&nbsp;&nbsp;&nbsp;
														<i class="fa fa-check-square" aria-hidden="true"></i> Done!
														<a class="color-tertiary" style="cursor: pointer; font-size: 12px" @click="editChaseHPF()">
															<span style="font-size: 12px" class="fa fa-edit" /> Change
														</a>
													</span>
												</div>

												<div v-if="chaseUid">
													<div v-if="validationErrors.general" class="hidden-sm hidden-xs" style="width: 100%; text-align: center;">
														<div class="color-primary inline-block" style="width: 100%; text-align: center;">
															<ul class="hpf-error-message">
																<li v-for="error in validationErrors.general" :key="error">{{ error }}</li>
															</ul>
														</div>
													</div>													

													<div v-if="!chaseHPFPaymentComplete">
														<iframe v-if="isProduction" id="hpfFrame" :src="'https://chase.hostedpaymentservice.net/hpf/?uid=' + chaseUid"></iframe>
														<iframe v-if="!isProduction" id="hpfFrame" :src="'https://chase-var.hostedpaymentservice.net/hpf/?uid=' + chaseUid"></iframe>
													</div>

													<div v-if="validationErrors.general" class="col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 hidden-lg hidden-md">
														<div class="color-primary inline-block" style="width: 100%; text-align: center;">
															<ul class="hpf-error-message">
																<li v-for="error in validationErrors.general" :key="error">{{ error }}</li>
															</ul>
														</div>
													</div>

												</div>

												<div v-if="!showNewCardFrame || (showNewCardFrame && chaseHPFPaymentComplete)" class="row">
													<div class="col-xs-12 p-tb-20 text-center">
														<button type="submit" class="button" :disabled="orderInFlight" tabindex="1" @click="placeOrder()">Place Order</button>
													</div>
												</div>
											</div>
											<div v-if="giftCardCoversTotal" class="col-xs-12 p-tb-20 text-center">
												<button type="submit" class="button" :disabled="orderInFlight" tabindex="1" @click="placeOrder()">Place Order</button>
											</div>
											<div v-if="savedCardIsSelected && !giftCardCoversTotal">
												<div v-if="validationErrors.general" class="hidden-sm hidden-xs" style="width: 100%; text-align: center;">
													<div class="color-primary inline-block" style="width: 100%; text-align: center;">
														<ul class="hpf-error-message">
															<li v-for="error in validationErrors.general" :key="error">{{ error }}</li>
														</ul>
													</div>
												</div>
												<div v-if="!savedPaymentMethods || savedPaymentMethods && selectedPaymentID != '--'" class="m-t-20">
													<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 " :class="{'has-error': validationErrors.cc_number}">
														<div class="col-md-4 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
															<label class="align-self-c" for="card-number"><span ng-show="true" class="required-asterisk color-primary">*</span>CARD NUMBER</label>
														</div>
														<div class="col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 ">
															<input id="card-number" v-model="paymentInfo.cc_number" type="tel" class="form-control" autocomplete="billing cc-number" maxlength="" disabled tabindex="1">
															<i class="fa form-control-feedback bootstrap-text-icon" aria-hidden="true" />
														</div>
													</div>

													<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.name_on_card}">
														<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
															<label class="align-self-c" for="cardholder-name"><span class="required-asterisk color-primary">*</span>CARDHOLDER NAME</label>
														</div>
														<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
															<input id="cardholder-name" v-model="paymentInfo.name_on_card" disabled type="text" class="form-control" autocomplete="billing cc-name" tabindex="1">
															<i class="fa form-control-feedback bootstrap-text-icon" aria-hidden="true" />
														</div>
														<div v-if="validationErrors.name_on_card" class="col-lg-7 col-md-7 col-lg-offset-5 col-md-offset-5 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 ">
															<span class="color-primary inline-block">{{ validationErrors.name_on_card }}</span>
														</div>
													</div>

													<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
														<div class="col-md-4 col-sm-12 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
															<label class="align-self-c">
																<span class="required-asterisk color-primary">*</span>EXPIRATION DATE
															</label>
														</div>
		
														<div class="col-md-3 col-sm-6 col-xs-6 p-lr-5 sm-p-lr-0 ">
															<div class="arrow" :class="{'has-error': validationErrors.card_exp_month }">
																<select
																	id="card_exp_month"
																	v-model="paymentInfo.card_exp_month"
																	class="form-control"
																	autocomplete="billing cc-exp-month"
																	tabindex="1"
																>
																	<option value="" disabled="disabled" />
																	<option v-for="month in ccMonths" :key="month.label" :value="month.value">{{ month.label }}</option>
																</select>
															</div>
		
															<div v-if="validationErrors.card_exp_month" style="">
																<span class="color-primary inline-block ng-binding">{{ validationErrors.card_exp_month }}</span>
															</div>
														</div>
		
														<div class="col-md-3 col-sm-6 col-xs-6 p-lr-5 sm-p-r-0 ">
															<div class="arrow" :class="{'has-error': validationErrors.card_exp_year }">
																<select
																	id="card_exp_year"
																	v-model="paymentInfo.card_exp_year"
																	class="form-control"
																	autocomplete="billing cc-exp-year"
																	tabindex="1"
																>
																	<option value="" disabled="disabled" />
																	<option v-for="year in ccYears" :key="year.label" :value="year.value">{{ year.label }}</option>
																</select>
															</div>
		
															<div v-if="validationErrors.card_exp_year" style="">
																<span class="color-primary inline-block ng-binding">{{ validationErrors.card_exp_year }}</span>
															</div>
														</div>
													</div>

													<div class="col-xs-12 p-tb-20 text-center">
														<button type="submit" class="button" :disabled="orderInFlight" tabindex="1" @click="placeOrder()">Place Order</button>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div v-if="payPalTabActive" id="paypal-tab" class="tab-pane fade" :class="{'active in': payPalTabActive}">
										<div class="p-t-25 tab-pane-bordered-box sg-form">
											<div class="row" v-if="validationErrors.paypal">
												<div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 p-tb-20">
													<div class="m-b-20" :class="{'has-error': validationErrors.paypal}">
														<span class="color-primary inline-block">{{ validationErrors.paypal }}</span>
													</div>
												</div>
											</div>

											<BillingAddressForm
												ref="billingAddressFormRefPP"
												:show-address-form="true"
												:opted-in-phone-list="optedInPhoneNumbers"
												:saved-card-is-selected-prop="savedCardIsSelected"
												:saved-card-billing-address="savedCardBillingAddress"
												@qas-fingerprint="onQasFingerprintChange"
											/>

											<div class="row">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<div class="col-md-8 col-md-offset-4 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
														<GiftCertificateInput />
													</div>
												</div>
											</div>

											<div class="row">
												<div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 p-tb-20">
													Please add all Promo Codes, Gift Cards and discounts prior to clicking the PayPal button.
												</div>
											</div>

											<div class="row">
												<div class="col-xs-12 p-tb-20">
													<PaypalButtons paypal-button-type="paypal" :billing-form-ref="currentBillingFormRef"></PaypalButtons>
												</div>
											</div>
										</div>
									</div>
									<div v-if="payPalInstallmentTabActive" id="paypal-installment-tab" class="tab-pane fade" :class="{'active in': payPalInstallmentTabActive}">
										<div class="p-t-10 tab-pane-bordered-box sg-form">
											<div class="row">
												<div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 p-tb-20">
													<div v-if="validationErrors.paypal" class="m-b-20" :class="{'has-error': validationErrors.paypal}">
														<span class="color-primary inline-block">{{ validationErrors.paypal }}</span>
													</div>
												</div>
											</div>

											<div class="row">
												<div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 p-tb-20">
													<PaypalMessages page="billing"></PaypalMessages>
												</div>
											</div>

											<BillingAddressForm
												ref="billingAddressFormRefPPI"
												:show-address-form="true"
												:opted-in-phone-list="optedInPhoneNumbers"
												:saved-card-is-selected-prop="savedCardIsSelected"
												:saved-card-billing-address="savedCardBillingAddress"
												@qas-fingerprint="onQasFingerprintChange"
											/>

											<div class="row">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<div class="col-md-8 col-md-offset-4 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
														<GiftCertificateInput />
													</div>
												</div>
											</div>

											<div class="row">
												<div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 p-tb-20">
													Please add all Promo Codes, Gift Cards and discounts prior to clicking the PayPal button.
												</div>
											</div>

											<div class="row">
												<div class="col-xs-12 p-tb-20">
													<PaypalButtons paypal-button-type="paylater" :billing-form-ref="currentBillingFormRef"></PaypalButtons>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- END Tabbed Payment Method -->

						<div class="row m-b-50">
							<div class="col-xs-12 col-sm-4" />
							<div class="col-xs-12 col-sm-8 sg-form">
								<PromoCode title="promo code" type="discount_code" change-hook="regenLineitems" checkout-step="cart" :cart="cart" />
							</div>
						</div>
					</div>
					<div id="order-sum-outer">
						<div class="col-md-4 order-sum-contain">
							<CartSummary page="billing" />
						</div>
					</div>
					<br class="clear">
					<CheckoutNavigation
						page="billing"
						:order-in-flight="orderInFlight"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useCartStore } from '../../../stores/CartStore'
import { useUserStore } from '../../../stores/UserStore.js'
import { usePromoCodeStore } from '../../../stores/PromoCodeStore'
import formatDateMixin from '../../../mixins/date-formatter-mixin.js'
import checkoutHelpers from '../../../mixins/checkout-global-functions.js'
import { emitter } from '../../../main.js'
import BillingAddressForm from './billing-address-form.vue'
import CartSummary from '../cart-summary.vue'
import GiftCertificateInput from '../../global/gift-certificate-input.vue'
import CheckoutNavigation from '../checkout-navigation.vue'
import CheckoutSteps from '../checkout-steps.vue'
import PromoCode from '../../global/promo-code.vue'
import PaypalButtons from "../paypal-buttons.vue"
import PaypalMessages from "../paypal-messages.vue"


const axios = require('axios')

const defaultValidationErrors = () => ({
	cc_number: '',
	name_on_card: '',
	card_exp_month: '',
	card_exp_year: '',
	card_cvc: '',
	general: []
})

const defaultPaymentInfo = () => ({
	payt_id: '--',
	cc_number: '',
	name_on_card: '',
	card_exp_month: '',
	card_exp_year: '',
	card_cvc: ''
})

export default {
	name: 'BillingPage',
	components: {
		BillingAddressForm,
		CartSummary,
		GiftCertificateInput,
		CheckoutNavigation,
		CheckoutSteps,
		PromoCode,
		PaypalButtons,
		PaypalMessages
	},
	mixins: [
		formatDateMixin,
		checkoutHelpers
	],
	props: {
		isProduction: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			cart: {},
			ccTabActive: true,
			chaseUid: null,
			chaseHPFPaymentComplete: false,
			payPalTabActive: false,
			payPalInstallmentTabActive: false,
			paypalSplitTest: 'B',
			payPalGuid: '',
			savedPaymentMethods: false,
			selectedPaymentID: '--',
			savedCardIsSelected: false,
			manualPaymentOptionErrors: {},
			showCvv: true,
			paymentInfo: defaultPaymentInfo(),
			ccMonths: [
				{ 'value': 1,  label: '01 - Jan' },
				{ 'value': 2,  label: '02 - Feb' },
				{ 'value': 3,  label: '03 - Mar' },
				{ 'value': 4,  label: '04 - Apr' },
				{ 'value': 5,  label: '05 - May' },
				{ 'value': 6,  label: '06 - Jun' },
				{ 'value': 7,  label: '07 - Jul' },
				{ 'value': 8,  label: '08 - Aug' },
				{ 'value': 9,  label: '09 - Sep' },
				{ 'value': 10, label: '10 - Oct' },
				{ 'value': 11, label: '11 - Nov' },
				{ 'value': 12, label: '12 - Dec' },
			],
			ccYears: [],
			validationErrors: defaultValidationErrors(),
			showGCBoxFlag: false,
			optedInPhoneNumbers: [],
			QASComplete: false,
			skipQAS: true,
			billingAddressSameAsShipping: false,
			getPerpetualSubscription: false,
			lastQASFingerprint: '',
			orderInFlight: false,
			currentBillingFormRef: null
		}
	},
	computed: {
		giftCardCoversTotal() {
			return useCartStore().cart?.summary_section_totals?.cart_net_total == 0
		},
		hasAutoDelivery() {
			return useCartStore().isAutoDelivery
		},
		savedCardBillingAddress() {
			return this.savedPaymentMethods[this.selectedPaymentID]?.billing_address
		},
		showNewCardFrame() {
			return !this.giftCardCoversTotal && (!this.savedPaymentMethods || (this.savedPaymentMethods && this.selectedPaymentID != '--' && !this.savedCardIsSelected))
		},
		isPayPalTestB() {
			let abTests = useCartStore().getABData
			if(abTests && abTests.hasOwnProperty('paypal_installments')) {
				return abTests.paypal_installments == 'B'
			}

			return false
		},
		showSavedCardSelection() {
			return (this.savedPaymentMethods && Object.keys(this.savedPaymentMethods).length > 0) && (this.cart?.summary_section_totals?.cart_net_total > 0)
		},
		showAddressForm() {
			return !this.savedPaymentMethods || (this.savedPaymentMethods && this.selectedPaymentID != '--')
		}
	},
	created(){},
	mounted() {
		// Chase HPF Post Message Functions
		window.handlePaymentErrors = this.chaseHPFErrorHandler
		window.handleTransactionErrors = this.chaseHPFTransactionErrorHandler
		window.completePayment = this.chaseHPFCompleteHandler
		window.hpfReady = this.chaseHPFReadyHandler
		window.startPayment = this.chaseHPFStartPaymentHandler
		window.cancelPayment = this.chaseHPFCancelPaymentHandler
		window.clearTransactionErrors = this.chaseHPFClearErrors

		// Prep and Load the Chase HPF Script
		let hpfParentScript = document.createElement('script')
		if(this.isProduction) {
			hpfParentScript.setAttribute('src', 'https://chase.hostedpaymentservice.net/includes/hpfParent.min.js')
		} else {
			hpfParentScript.setAttribute('src', 'https://chase-var.hostedpaymentservice.net/includes/hpfParent.min.js')
		}
		document.head.appendChild(hpfParentScript)

		emitter.on('cart-updated-from-server', (evt) => {
			if(this.cart.chase_hpf_response?.status_message?.toLowerCase() == 'approved') {
				this.chaseHPFPaymentComplete = true
				this.chaseUid = this.cart.chase_hpf_response.uid
			}

			if(this.cart?.summary_section_totals?.cart_net_total == 0) {
				this.selectedPaymentID = 'new'
			}
		})
		
		emitter.on('gift-card-removed', (evt) => {
			this.reloadHPF()
		})

		emitter.on('cart-tax-updated', (evt) => {
			if(this.cart.chase_hpf_response?.status_message?.toLowerCase() == 'approved') {
				this.chaseHPFPaymentComplete = true
				this.chaseUid = this.cart.chase_hpf_response.uid
			}

			if(this.cart?.summary_section_totals?.cart_net_total == 0) {
				this.selectedPaymentID = 'new'
			}
		})

		emitter.on('new-payment-selected', (evt) => {
			if(this.cart.chase_hpf_response?.status_message?.toLowerCase() == 'approved') {
				this.chaseHPFPaymentComplete = true
				this.chaseUid = this.cart.chase_hpf_response.uid
			} else if(!this.giftCardCoversTotal) {
				this.chaseHPFPaymentComplete = false
				this.reloadHPF()
			}
		})

		// Show reduced header while in checkout
		this.showShortHeader()

		// Set the mini-cart count
		this.cartStore = useCartStore()
		if(!this.cartStore.cartIsValid()) {
			this.clearStoresAndBailOut()
		}

		this.cartStore.calculateTax(this.getPage())

		emitter.on('cart-tax-updated', (evt) => {
			this.hideLoadingOverlay()
		})

		this.getPerpetualSubscription = this.cartStore.getPerpetualSubscription

		this.cart = storeToRefs(this.cartStore).fullCart
		this.QASComplete = storeToRefs(this.cartStore).QASComplete

		// Get Saved Payment Methods
		axios.get('/api/customer/payment-methods/')
			.then(response => {
				if(response.data.payment_methods && Object.keys(response.data.payment_methods).length) {
					this.savedPaymentMethods = response.data.payment_methods

					// IF the customer has only 1 saved CC AND PAYPAL is NOT currently selected....pre-select the one saved CC
					if(this.ccTabActive && Object.keys(this.savedPaymentMethods).length == 1) {
						this.selectedPaymentID = Object.keys(this.savedPaymentMethods)[0]
						this.chooseSavedPaymentSource()
					}

					for(const paytId in this.savedPaymentMethods) {
						if(this.savedPaymentMethods.hasOwnProperty(paytId)) {
							if(this.savedPaymentMethods[paytId].billing_address.sms_opt_in && this.savedPaymentMethods[paytId].billing_address.phone) {
								this.optedInPhoneNumbers.push(this.savedPaymentMethods[paytId].billing_address.phone.replace(/\D/g,''))
							}
						}
					}						
				} else {
					emitter.emit('new-payment-selected')
				}
			})

		// Get list of CC Expiration years
		axios.get('/api/customer/cc-years')
			.then(response => {
				this.ccYears = response.data.ccYears
			})

		// PLACE ORDER BUTTON CLICKED
		// First this is to pre-validate payment info here
		// Second we tell the billing-address-form to check the billing address
		// Third, we submit the order
		emitter.on('place-order-button-clicked', (evt) => {
			var valid = true
			if(!this.giftCardCoversTotal && this.paymentInfo.type != 'paypal') { 
				valid = this.validateCreditCardData()
			}

			if(valid) {
				emitter.emit('payment-info-validated')
			} else {
				this.orderInFlight = false
				this.scrollToError()
			}
		})

		emitter.on('place-order-billing-address-prevalidated', (evt) => {
			var sms_info = {
				sms_opt_in: this.cartStore.getSMSOptInFlag
			};

			let paytId = this.selectedPaymentID
			if(this.payPalTabActive || (this.ccTabActive && this.selectedPaymentID == 'new')) {
				paytId = null
			}

			const incomingFingerprint = this.$utilities.generateAddressFingerprint(evt.billingAddress)

			if (incomingFingerprint !== this.lastQASFingerprint) {
				this.skipQAS = false

				if (evt.billingAddress.addr_id) {
					const savedFingerprint = this.$utilities.generateAddressFingerprint(useUserStore().getAddressById(evt.billingAddress.addr_id))

					if (incomingFingerprint === savedFingerprint) {
						this.skipQAS = true
					}
				}
			}

			let data = {
				billing_info: evt.billingAddress,
				payt_id: paytId,
				skip_qas: this.skipQAS
			}

			this.clearValidationErrors()

			this.showLoadingOverlay()
			this.orderInFlight = true

			this.cartStore.updateBillingAddress(data).then((response) => {
				if(typeof(response.data.field_errors) != 'undefined' && !response.data.field_errors.passed) {
					if(response.data.field_errors.recipient_errors) {
						// There are recipient errors.
						// Recipient errors must be resolved on the shipping page
						// Stash the validation errors on the cartStore so we can
						// check them when we get to the shipping page
						emitter.emit('in-flight-order-reset')
						this.cartStore.validationErrors = response.data.field_errors
						window.location.href = '/checkout/shipping'
					}
					
					// CHECK FOR BILLING ADDRESS QAS ISSUES
					if(typeof(response.data.field_errors.billing_info) != 'undefined') {
						// ADDRESS HAS VALIDATION ISSUES AND REQUIRES CUSTOMER ACTION
						if(
							typeof response.data.field_errors.billing_info[0].address_1 != 'undefined'
							&& response.data.field_errors.billing_info[0].address_1[0][1] == 'ups_addr_suggest_warning'
						) {
							emitter.emit('billing-qas-prompt', {
								qasData: response.data.field_errors.billing_info[0].address_1[0][3]
							})
						} else {
							if (this.$refs.billingAddressFormRef) {
								this.$refs.billingAddressFormRef.handleValidationErrors(response.data.field_errors.billing_info)
								this.$refs.billingAddressFormRef.showStaticBillingInfoWithEdit = false
							} else {
								this.$sentry.captureException(new Error('Attempt to reference nonexistant Vue ref billingAddressFormRef'))
							}

							this.scrollToError()
						}
					}

					this.orderInFlight = false
					this.hideLoadingOverlay()
				} else {
					// In case the address was auto-fixed
					// swap what we have with what came back from the server
					this.cart.billing_info = response.data.cart.billing_info
					emitter.emit('billing-info-auto-fixed', {autoFixAddress: response.data.cart.billing_info})

					this.lastQASFingerprint = this.$utilities.generateAddressFingerprint(response.data.cart.billing_info)

					if((this.ccTabActive)) {
						this.paymentInfo.type = 'credit_card'
						this.paymentInfo.payt_id = this.selectedPaymentID

						if(!this.giftCardCoversTotal && this.chaseUid) {
							this.paymentInfo.chase_uid = this.chaseUid
						}
					} else if(this.paypalGuid != '') {
						this.paymentInfo = {
							type: 'paypal',
							paypal_guid: this.paypalGuid
						}
					}

					if(this.giftCardCoversTotal) {
						this.paymentInfo.type = 'giftcard'
					}

					this.cartStore.placeOrder({
						payment_info: this.paymentInfo,
						sms_info: sms_info
					}).then((response) => {
						if(typeof response.data.order_guid != 'undefined') {
							// Clear local storage and send to reciept page
							useCartStore().completeCart()
							usePromoCodeStore().clearCode()
							// Analytics tracking events
							this.fireRecordCheckoutDecisionEvent('paymentStep', this.paymentInfo.type)
							window.location.href = '/checkout/receipt/' + response.data.order_guid;
						} else {
							// Problem placing order --- handle errors
							this.handlePlaceOrderErrorResponse(true)
						}
					}).catch((error) => {
						if (error.response) {
							this.handlePlaceOrderErrorResponse(true)
						} else {
							this.$sentry.captureException(error)
						}

						this.orderInFlight = false
					}).finally(() => {
						this.hideLoadingOverlay()
					})
				}
			}).catch((error) => {
				this.orderInFlight = false
				this.hideLoadingOverlay()
				this.$sentry.captureException(error)
			})
			.finally(() => {
				// this.hideLoadingOverlay()
			})

		})

		emitter.on('gift-certificate-successfully-removed', (evt) => {
			this.cartStore.updateCartDataFromServer()
		})

		emitter.on('promo-code-success', evt => {
			this.cartStore.calculateTax('billing')
		})

		emitter.on('cart-not-found', (evt) => {
			this.clearStoresAndBailOut()
		})

		emitter.on('paypal-failed_create-order', () => {
			this.validationErrors.paypal = "We're sorry, but there was a problem communicating with PayPal. Please select a different payment method."
			this.scrollToError()
		})

		emitter.on("paypal-complete", (evt) => {
			this.paypalGuid = evt.paypalGuid
		})
	},
	methods: {
		reloadHPF() {
			axios.get('/api/checkout/get-chase-uid')
				.then(response => {
					this.chaseUid = response.data.uid
					let hpfFrame = document.getElementById('hpfFrame')
					if(hpfFrame) {
						hpfFrame.contentWindow.location.reload();
					}
				})
		},
		chaseHPFErrorHandler(data) {
			this.handlePlaceOrderErrorResponse(false)
		},
		chaseHPFTransactionErrorHandler(data) {
			this.handlePlaceOrderErrorResponse(false)
		},
		chaseHPFClearErrors() {
			this.validationErrors = defaultValidationErrors()
		},
		chaseHPFCompleteHandler(data) {
			// This is called when the customer has successfully completed the payment
			// We need to submit the order
			this.cartStore.saveChaseHPFProfile(data)
			.then(response => {
				this.chaseHPFPaymentComplete = true
				emitter.emit('payment-info-validated')
			})
			.catch(error => {
				this.$sentry.captureException(error)
			})
		},
		chaseHPFCancelPaymentHandler() {
			// alert("postMessage function cancelPayment is called. \n You have canceled the payment.");
		},
		editChaseHPF() {
			this.chaseHPFPaymentComplete = false
			this.chaseUid = null
			this.reloadHPF()
		},
		placeOrder() {
			emitter.emit('place-order-button-clicked')
		},
		validateCreditCardData() {
			let valid = true
			// Start by clearing any validation errors from a previous attempt
			this.validationErrors = defaultValidationErrors()

			if(this.savedPaymentMethods && this.paymentInfo.payt_id == '--') {
				this.validationErrors.selected_payment = "Please select a payment method."
				return false
			}

			// TEST THE EXP DATE
			if(this.paymentInfo.payt_id != 'new' && this.paymentInfo.payt_id != '--') {
				if ( !this.paymentInfo.card_exp_month || this.paymentInfo.card_exp_month == '' ) {
					valid = false
					this.validationErrors.card_exp_month = 'Required';
				} else if ( ! this.paymentInfo.card_exp_month.toString().match(/^\d{1,2}$/) ) {
					valid = false
					this.validationErrors.card_exp_month = 'Card expiration month is invalid';
				}

				if ( !this.paymentInfo.card_exp_year || this.paymentInfo.card_exp_year == '' ) {
					valid = false
					this.validationErrors.card_exp_year = 'Required';
				} else if ( ! this.paymentInfo.card_exp_year.toString().match(/^(\d{1,2}|\d{4})$/) ) {
					valid = false
					this.validationErrors.card_exp_year = 'Card expiration year is invalid';
				}

				if ( this.paymentInfo.card_exp_month != '' && this.paymentInfo.card_exp_year != '' ) {
					// Test whether the date entered has passed
					var curDate = new Date();
					var curYear = parseInt(curDate.getFullYear());
					var curMonth = parseInt(curDate.getMonth()) + 1; // Select months are 1 based....js Date object months are 0 based

					if( parseInt(this.paymentInfo.card_exp_year) < curYear ) {
						// Year has passed - auto fail
						valid = false
						this.validationErrors.card_exp_month = ' ';
						this.validationErrors.card_exp_year = 'Please update your expiration date';
					} else if( parseInt(this.paymentInfo.card_exp_year) === curYear && parseInt(this.paymentInfo.card_exp_month) < curMonth ) {
						// Month has passed - auto fail
						valid = false
						this.validationErrors.card_exp_month = ' ';
						this.validationErrors.card_exp_year = 'Please update your expiration date';
					}
				}

				// TEST THE CVV
				if (this.showCvv && (!this.paymentInfo.card_cvc || this.paymentInfo.card_cvc === '')) {
					valid = false
					this.validationErrors.card_cvc = 'CVV is required';
				} else {
					let regex = new RegExp(/^[0-9]{3,4}$/)
					if (this.showCvv && !regex.test(this.paymentInfo.card_cvc) ) {
						valid = false
						this.validationErrors.card_cvc = 'Invalid CVV';
					}
				}
			}

			return valid
		},
		paymentMethodTabChange(tab) {
			switch(tab) {
				case 'cc-tab':
					this.ccTabActive = true
					this.payPalTabActive = false
					this.payPalInstallmentTabActive = false
					this.paypalGuid = ''
					if(this.savedCardIsSelected) {
						this.skipQAS = true
					} else {
						this.skipQAS = false
					}

					this.paymentInfo.type = 'credit-card'

					this.$nextTick(() => {
						this.currentBillingFormRef = this.$refs.billingAddressFormRefCC
					})
				
					emitter.emit('credit-card-selected')
					this.reloadHPF()
					break
				case 'paypal-tab':
					this.ccTabActive = false
					this.payPalTabActive = true
					this.payPalInstallmentTabActive = false
					this.paymentInfo.type = 'paypal'
					this.chaseUid = null
					if(this.billingAddressSameAsShipping) {
						this.skipQAS = true
					} else {
						this.skipQAS = false
					}
					this.$nextTick(() => {
						this.currentBillingFormRef = this.$refs.billingAddressFormRefPP
					})
					emitter.emit('paypal-selected')
					break
				case 'paypal-installment-tab':
					this.ccTabActive = false
					this.payPalTabActive = false
					this.payPalInstallmentTabActive = true
					this.paymentInfo.type = 'paypal'
					this.chaseUid = null
					if(this.billingAddressSameAsShipping) {
						this.skipQAS = true
					} else {
						this.skipQAS = false
					}
					this.$nextTick(() => {
						this.currentBillingFormRef = this.$refs.billingAddressFormRefPPI
					})
					emitter.emit('paypal-installment-selected')
					break
			}
		},
		chooseSavedPaymentSource() {				
			this.showQASOptions = false;
			if( this.selectedPaymentID == 'new' ) {
				this.paymentInfo = {
					payt_id: 'new'
				}
				this.clearValidationErrors()
				this.skipQAS = false
				this.showCvv = true
				this.clickedEditStaticBillingInfo = true
				this.savedCardIsSelected = false

				emitter.emit('new-payment-selected')
				this.reloadHPF()

			} else if(this.selectedPaymentID == '--') {
				emitter.emit('existing-saved-payment-selected')
				this.savedCardIsSelected = false
				this.paymentInfo = {
					payt_id: '--'
				}
			} else {
				emitter.emit('existing-saved-payment-selected')
				this.chaseUid = null
				this.clearValidationErrors()
				this.showCvv = false;
				this.clickedEditStaticBillingInfo = false;
				this.savedCardIsSelected = true;

				let selectedCardBillingAddress = this.savedPaymentMethods[this.selectedPaymentID].billing_address
				this.paymentInfo = this.savedPaymentMethods[this.selectedPaymentID].card_info
				this.paymentInfo.payt_id = this.selectedPaymentID
				emitter.emit('saved-payment-selected', {savedCardIsSelected: this.savedCardIsSelected, billingAddress: selectedCardBillingAddress})
				this.lastQASFingerprint = this.$utilities.generateAddressFingerprint(selectedCardBillingAddress)
				this.validateCreditCardData()
			}

			this.stashedExpiration = {
				month: this.paymentInfo.card_exp_month,
				year: this.paymentInfo.card_exp_year
			};
			this.cvvCheck();
		},
		cvvCheck() {
			if(typeof(this.paymentInfo) != 'undefined' && typeof(this.paymentInfo.card_exp_month) != 'undefined' && typeof(this.stashedExpiration) != 'undefined') {
				if (this.paymentInfo.payt_id == 'new' || (this.paymentInfo.card_exp_month != this.stashedExpiration.month || this.paymentInfo.card_exp_year != this.stashedExpiration.year )) {
					this.showCvv = true;
				} else {
					this.showCvv = false;
				}
			}
		},
		showGCBox() {
			this.showGCBoxFlag = !this.showGCBoxFlag
		},
		clearValidationErrors() {
			this.validationErrors = defaultValidationErrors()
		},
		onQasFingerprintChange(fingerprint) {
			this.lastQASFingerprint = fingerprint
			this.skipQAS = true
		},
		handlePlaceOrderErrorResponse(reloadHPF = false) {
			this.validationErrors.general = ["We're sorry.  There was a problem with your payment information.  Please try again."]

			this.orderInFlight = false
			this.hideLoadingOverlay()
			this.chaseHPFPaymentComplete = false
			if(reloadHPF) {
				this.chaseUid = null
				this.reloadHPF()
			}
		},
		scrollToError() {
			setTimeout(() => {
				if($('.has-error:visible:first').length && $('.has-error:visible:first').offset()) {
					$('html, body').animate({
						scrollTop: $('.has-error:visible:first').offset().top - 75
					}, 500)
				}
			}, 500);
		}
	}
};
</script>
